import styled from 'styled-components';
import { iosWorld } from 'react-icons-kit/ionicons/iosWorld';
import { getStoredLanguage, setStoredLanguage } from '../LocalStorage';
import Icon from 'react-icons-kit';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

const LanguageSelectorWrapper = styled.div`
  display: flex;
  gap: 2;
`;
const CustomIcon = styled(Icon)`
  display: block;
  color: #fff;
  @media (max-width: 767px) {
    display: none !important;
  }
`;
const LanguageSelectorSelect = styled.select`
  background-color: transparent;
  border: none;
  height: 25px;
  width: 73px;
  border-radius: 4px;
  color: #fff;
`;

//Select multilangage
const LanguageSelector = () => {
  const router = useRouter();
  const { locale } = router;
  const [selectedLanguage, setSelectedLanguage] = useState();

  useEffect(() => {
    setSelectedLanguage(locale);
  }, []);

  const languages = [
    { code: 'en-US', name: 'English' },
    { code: 'fr-fr', name: 'Français' },
  ];

  const handleLanguageChange = useCallback(
    (e) => {
      const selectedLocale = e.target.value;
      setSelectedLanguage(selectedLocale);
      setStoredLanguage(selectedLocale);
      router.push(router.pathname, router.asPath, { locale: selectedLocale });
    },
    [router],
  );

  const LanguageOption = React.memo(({ code, name }) => (
    <option key={code} value={code} style={{ color: '#2E4057' }}>
      {name}
    </option>
  ));

  return (
    <LanguageSelectorWrapper>
      <CustomIcon icon={iosWorld} className='multilang-icon' size={25} />
      <LanguageSelectorSelect
        value={selectedLanguage}
        onChange={handleLanguageChange}
      >
        {languages.map((language) => (
          <LanguageOption key={language.code} {...language} />
        ))}
      </LanguageSelectorSelect>
    </LanguageSelectorWrapper>
  );
};
export default LanguageSelector;
