import styled from 'styled-components';
import { saasModernTheme } from 'common/theme/saasModern';

export const ContainerNavBar = styled.header`
  height: ${saasModernTheme.space[15]}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 0;
`;

export const LogoNavBar = styled.a``;

export const LinkNavBar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 950px) {
    display: none;
  }
`;

export const ButtonOpenDrawer = styled.button`
  display: none;
  background: transparent;
  border: 0;
  color: #ffffff;
  margin: 0;
  padding: 0;
  @media (max-width: 950px) {
    display: block;
  }
`;

export const ContainerDrawer = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
  width: 90%;
  margin: 0 auto;
  padding: 0;
`;
