import React from 'react';
import NextImage from '../NextImage';
import Link from 'next/link';
import LanguageSelector from '../LangageSelector';
import { NavWrapper, NavLi, ContentLanguage } from './style';
import { useRouter } from 'next/router';

const RenderLinkWithIcon = ({ menu }) => {
  return (
    <div className='icon-login'>
      {menu.icon ? (
        <NextImage className='icon' src={menu.icon} alt={menu.label} />
      ) : (
        ''
      )}
      <a
        className={menu.icon ? 'icon-label' : 'no-icon-label'}
        href={menu.path}
      >
        {menu.label}
      </a>
    </div>
  );
};

const ScrollSpyMenu = ({
  className,
  menuItems,
  locale,
  drawerClose,
  ...props
}) => {
  const scrollItems = [];
  const router = useRouter();

  // convert menu path to scrollspy items
  menuItems.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  const activeTabs = (location) => {
    if (router.pathname.includes('/blog')) {
      return location.includes('/blog');
    }

    return router.pathname === location;
  };

  return (
    <NavWrapper>
      {menuItems.map((menu, index) => (
        <NavLi key={`menu-item-${index}`} active={activeTabs(menu.location)}>
          {menu.staticLink ? (
            <RenderLinkWithIcon menu={menu} />
          ) : (
            <Link href={menu.path}>{menu?.intl[locale]}</Link>
          )}
        </NavLi>
      ))}
      <ContentLanguage>
        <LanguageSelector />
      </ContentLanguage>
    </NavWrapper>
  );
};

export default ScrollSpyMenu;
