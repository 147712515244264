const STORAGE_KEY = 'selectedLanguage';

const getStoredLanguage = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(STORAGE_KEY);
  }
  return null;
};

const setStoredLanguage = (language) => localStorage.setItem(STORAGE_KEY, language);

export { getStoredLanguage, setStoredLanguage };
