export const MENU_ITEMS = [
  // {
  //   label: 'Widget',
  //   path: '../../chatbot-widget',
  //   offset: '0',
  //   intl: 'Widget',
  //   location: '/chatbot-widget',
  // },
  {
    label: 'Pricing',
    path: '../../pricing',
    location: '/pricing',
    offset: '0',
    intl: { 'en-us': 'Pricing', 'fr-fr': 'Tarification' },
  },
  {
    label: 'Company',
    path: '../../company',
    location: '/company',
    offset: '0',
    intl: { 'en-us': 'Company', 'fr-fr': 'Entreprise' },
  },
  {
    label: 'Blog',
    path: '../../blog',
    location: '/blog',
    offset: '0',
    intl: { 'en-us': 'Blog', 'fr-fr': 'Blog' },
  },
];

export const FOOTER_WIDGET = [
  {
    title: { 'en-us': 'About Us', 'fr-fr': 'À propos de nous' },
    menuItems: [
      {
        url: 'https://discord.gg/TpxmW8RbZa',
        text: { 'en-us': 'Discord', 'fr-fr': 'Discord' },
        blank: true,
      },
      {
        url: 'https://twitter.com/swiftask_ai',
        text: { 'en-us': 'X (Twitter)', 'fr-fr': 'X (Twitter)' },
        blank: true,
      },
      {
        url: 'https://www.facebook.com/swiftask',
        text: { 'en-us': 'Facebook', 'fr-fr': 'Facebook' },
        blank: true,
      },
      {
        url: 'https://www.linkedin.com/company/swiftask/',
        text: { 'en-us': 'Linkedin', 'fr-fr': 'Linkedin' },
        blank: true,
      },
      // {
      //   url: "#",
      //   text: "Copyright",
      // },
      // {
      //   url: "#",
      //   text: "Popular Campaign",
      // },
    ],
  },
  {
    title: { 'en-us': 'Our Information', 'fr-fr': 'Nos informations' },
    menuItems: [
      {
        url: 'https://www.swiftask.ai/privacy.html',
        text: {
          'en-us': 'Privacy Policy',
          'fr-fr': 'Politique de confidentialité',
        },
        blank: true,
      },
 
      {
        url: 'https://www.swiftask.ai/tos.html',
        text: {
          'en-us': 'Terms & Conditions',
          'fr-fr': 'Conditions générales',
        },
        blank: true,
      },
      {
        url: "https://docs.swiftask.ai/",
        text: {
          'en-us': 'Documentation',
          'fr-fr': 'Documentation',
        },
        blank: true,
      },
    ],
  },
];
