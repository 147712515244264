import React, { useState, useEffect } from 'react';
import NavbarStyle from './navbar.style';

const Navbar = ({ displayNavBar = true, children }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        // Adjust the scroll threshold as needed
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <NavbarStyle className={scrolled || displayNavBar  ? 'scrolled' : ''}>
      {children}
    </NavbarStyle>
  );
};

export default Navbar;
