import * as React from 'react';
import { StyledDrawer, CloseContainer, HeadDrawer } from './style';
import MenuBurgerClose from 'common/assets/image/logo/icon/menuBurgerClose';
import Swiftask from 'common/assets/image/logo/swiftask';
import Link from 'next/link';

const DrawerComponent = ({ open, toggleDrawer, children, anchor }) => {
  return (
    <StyledDrawer open={open} onClose={toggleDrawer} anchor={anchor || 'right'}>
      <HeadDrawer>
        <Link href='/'>
          <Swiftask />
        </Link>

        <CloseContainer onClick={toggleDrawer}>
          <MenuBurgerClose />
        </CloseContainer>
      </HeadDrawer>
      {children}
    </StyledDrawer>
  );
};

export default DrawerComponent;
