import React from 'react';
import Drawer from 'common/components/Drawer';
import { MENU_ITEMS } from 'common/data/SaasModern';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import BtnGetStarted from 'common/components/Button/BTNGetStarted';
import { ContainerDrawer } from './style';

const DrawerNavbar = ({ toggleHandler, isOpen, locale }) => {
  return (
    <Drawer open={isOpen} toggleDrawer={toggleHandler}>
      <ContainerDrawer>
        <ScrollSpyMenu
          className='mobile_menu'
          menuItems={MENU_ITEMS}
          drawerClose={true}
          offset={-100}
          locale={locale}
        />
        <BtnGetStarted locale={locale} />
      </ContainerDrawer>
    </Drawer>
  );
};

export default DrawerNavbar;
