import styled from 'styled-components';
import { saasModernTheme } from 'common/theme/saasModern';

const NavbarStyle = styled.header`
  /* Navbar default style */
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: content-box;
  z-index: 10;
  background: transparent; /* Change to desired color and opacity */
  transition: background-color 0.5s ease-in-out;
  &.scrolled {
    background: #1b1b1b9c;
  }
`;

export default NavbarStyle;
