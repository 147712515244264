import { Box, Drawer, IconButton, styled } from '@mui/material';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: 1000,

  '& .MuiDrawer-paper': {
    backgroundColor: '#1B1B1B9C',
    [theme.breakpoints.down(1024)]: {
      width: '40%',
    },
    [theme.breakpoints.down(450)]: {
      width: '100%',
    },
  },
}));

export const CloseContainer = styled(IconButton)(({ theme }) => ({
  padding: 0,
}));

export const HeadDrawer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: theme.spacing(2),
  marginTop: theme.spacing(4),
  padding: 0,
}));
